@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Regular"), local("GTWalsheimProRegular"),
    url(./GTWalsheimPro-Regular.woff2) format("woff2"),
    url(./GTWalsheimPro-Regular.woff) format("woff"),
    url(./GTWalsheimPro-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Bold"), local("GTWalsheimProBold"),
    url(./GTWalsheimPro-Bold.woff2) format("woff2"),
    url(./GTWalsheimPro-Bold.woff) format("woff"),
    url(./GTWalsheimPro-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
